import { createRouter, createWebHistory } from "vue-router";
import store from "@/store/index";
import Cookies  from 'vue-cookies';

const routes = [
  {
    path: "/",
    name: "/",
    component: () => import( "../components/signin.vue"),
    meta: { requiresAuth: false },
  },
  {
    path: "/home",
    name: "Home",
    component: () => import( "../components/index.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/registration",
    name: "Registration",
    component: () => import("../components/registration/registration.vue"),
    meta: { requiresAuth: true ,requiresRoles: ['ROLE_All', 'ROLE_Admissions_Admin',
     'ROLE_Issue_Leaveout_or_Suspension', 'ROLE_Add_or_Edit_students_and_Teachers']},
  },
  {
    path: "/students",
    name: "RegStudents",
    component: () => import("../components/registration/students.vue"),
    meta: { requiresAuth: true ,requiresRoles: ['ROLE_All', 'ROLE_Admissions_Admin',
     'ROLE_Issue_Leaveout_or_Suspension', 'ROLE_Add_or_Edit_students_and_Teachers']},
  },

  {
    path: "/transfers",
    name: "Transfers",
    component: () => import("../components/registration/transfers.vue"),
    meta: { requiresAuth: true ,requiresRoles: ['ROLE_All', 'ROLE_Admissions_Admin',
     'ROLE_Add_or_Edit_students_and_Teachers']},
  },
  {
    path: "/classes",
    name: "RegistrationClasses",
    component: () => import("../components/registration/classes.vue"),
    meta: { requiresAuth: true ,requiresRoles: ['ROLE_All', 'ROLE_Admissions_Admin',
    'ROLE_Add_or_Edit_students_and_Teachers']},
  },
  {
    path: "/teachers",
    name: "RegTeachers",
    component: () => import("../components/registration/teachers.vue"),
    meta: { requiresAuth: true ,requiresRoles: ['ROLE_All', 'ROLE_Admissions_Admin',
     'ROLE_Add_or_Edit_students_and_Teachers'] },
  },
  {
    path: "/staff",
    name: "RegStaff",
    component: () => import("../components/registration/staff.vue"),
    meta: { requiresAuth: true ,requiresRoles: ['ROLE_All', 'ROLE_Admissions_Admin',
     'ROLE_Add_or_Edit_students_and_Teachers'] },
  },
  {
    path: "/board",
    name: "Board",
    component: () => import("../components/registration/board.vue"),
    meta: { requiresAuth: true ,requiresRoles: ['ROLE_All', 'ROLE_Admissions_Admin',
     'ROLE_Add_or_Edit_students_and_Teachers'] },
  },
  {
    path: "/leaveouts",
    name: "leaveouts",
    component: () => import("../components/registration/leaveouts.vue"),
    meta: { requiresAuth: true ,requiresRoles: ['ROLE_All', 'ROLE_Admissions_Admin',
     'ROLE_Issue_Leaveout_or_Suspension', 'ROLE_Add_or_Edit_students_and_Teachers'] },
  },
  











  {
    path: "/finance",
    name: "Finance",
    component: () => import( "../components/finance/finance.vue"),
    meta: { requiresAuth: true ,requiresRoles: [
      'ROLE_All'
    , 'ROLE_finance_admin'
    , 'ROLE_Access_Pocket_Money'
    , 'ROLE_View_Dalily_Collections'
    , 'ROLE_Access_Other_Sources_of_income'
    , 'ROLE_Approve_Refund'
    , 'ROLE_Send_Fee_balances'
    , 'ROLE_Access_Expenses'
    , 'ROLE_Cross_Receipts'
    , 'ROLE_Set_Fee_Structure'
    , 'ROLE_Access_Accounts_dashboard'
    , 'ROLE_Pay_Fees'
    , 'ROLE_Process_Refund'
    , 'ROLE_View_Only'
  
  ] },
  },

  {
    path: "/financestudents",
    name: "FinStudents",
    component: () => import("../components/finance/students.vue"),
    meta: { requiresAuth: true ,requiresRoles: [
      'ROLE_All'
    , 'ROLE_finance_admin'
    , 'ROLE_Cross_Receipts'
    , 'ROLE_Set_Fee_Structure'
    , 'ROLE_Access_Accounts_dashboard'
    , 'ROLE_Pay_Fees'
    , 'ROLE_View_Only']},
  },

  {
    path: "/budgets",
    name: "Budgets",
    component: () => import( "../components/finance/budgets.vue"),
    meta: { requiresAuth: true ,requiresRoles: ['ROLE_All'
    , 'ROLE_create_budget','ROLE_View_Only','ROLE_finance_admin'] },
  },

  {
    path: "/refunds",
    name: "Refunds",
    component: () => import( "../components/finance/feerefunds.vue"),
    meta: { requiresAuth: true ,requiresRoles: ['ROLE_All', 'ROLE_Process_Refund',
    'ROLE_Approve_Refund','ROLE_View_Only','ROLE_finance_admin'] },
  },

  {
    path: "/supliers",
    name: "Supliers",
    component: () => import( "../components/finance/supliers.vue"),
    meta: { requiresAuth: true ,requiresRoles: ['ROLE_All', 'ROLE_Access_Expenses',
    'ROLE_View_Only','ROLE_finance_admin'] },
  },

  {
    path: "/paymentvouchers",
    name: "Pvs",
    component: () => import( "../components/finance/pvs.vue"),
    meta: { requiresAuth: true ,requiresRoles: ['ROLE_All', 'ROLE_Access_Expenses',
    'ROLE_View_Only','ROLE_finance_admin'] },
  },

  {
    path: "/studentbilling",
    name: "studentbilling",
    component: () => import( "../components/finance/studentbilling.vue"),
    meta: { requiresAuth: true ,requiresRoles: ['ROLE_All', 'ROLE_Set_Fee_Structure',
    'ROLE_View_Only','ROLE_finance_admin'] },
  },
  
  {
    path: "/accounttransactions",
    name: "accounttransactions",
    component: () => import( "../components/finance/bankaccounttransactions.vue"),
    meta: { requiresAuth: true ,requiresRoles: ['ROLE_All', 'ROLE_View_Financial_reports',
    'ROLE_View_Dalily_Collections','ROLE_View_Only','ROLE_finance_admin'] },
  },

  {
    path: "/otherfeesbalances",
    name: "otherbalances",
    component: () => import( "../components/finance/otherbalances.vue"),
    meta: { requiresAuth: true ,requiresRoles: ['ROLE_All','ROLE_finance_admin', 
    'ROLE_View_Only','ROLE_Pay_Fees'] },
  },

  {
    path: "/otherincomes",
    name: "OtherIncomes",
    component: () => import( "../components/finance/otherincomes.vue"),
    meta: { requiresAuth: true ,requiresRoles: ['ROLE_All','ROLE_finance_admin', 
    'ROLE_View_Only','ROLE_Access_Other_Sources_of_income'] },
  },

  {
    path: "/othercollections",
    name: "OtherReceipts",
    component: () => import( "../components/finance/otherreceipts.vue"),
    meta: { requiresAuth: true ,requiresRoles: ['ROLE_All','ROLE_finance_admin', 
    'ROLE_View_Only','ROLE_Pay_Fees'] },
  },

  {
    path: "/banks",
    name: "BankAccounts",
    component: () => import( "../components/finance/bankaccounts.vue"),
    meta: { requiresAuth: true ,requiresRoles: ['ROLE_All','ROLE_finance_admin', 
    'ROLE_View_Only'] },
  },

  {
    path: "/chartofaccounts",
    name: "ChartofAccounts",
    component: () => import( "../components/finance/chartofaccounts.vue"),
    meta: { requiresAuth: true ,requiresRoles: ['ROLE_All','ROLE_finance_admin', 'ROLE_View_Only'] },
  },

  {
    path: "/balancenotifications",
    name: "BalanceNotifications",
    component: () => import( "../components/finance/sendfeebals.vue"),
    meta: { requiresAuth: true ,requiresRoles: ['ROLE_All','ROLE_finance_admin', 'ROLE_Send_Fee_balances'] },
  },

  {
    path: "/invoicenotifications",
    name: "InvoiceNotifications",
    component: () => import( "../components/finance/sendfeeinvoices.vue"),
    meta: { requiresAuth: true ,requiresRoles: ['ROLE_All','ROLE_finance_admin', 'ROLE_Send_Fee_balances'] },
  },

  {
    path: "/smstopups",
    name: "BuySms",
    component: () => import( "../components/finance/buysms.vue"),
    meta: { requiresAuth: true ,requiresRoles: ['ROLE_All','ROLE_finance_admin', 'ROLE_Send_Fee_balances'] },
  },

  
  {
    path: "/feepayments",
    name: "Receipts",
    component: () => import( "../components/finance/receipts.vue"),
    meta: { requiresAuth: true ,requiresRoles: ['ROLE_All','ROLE_finance_admin', 
    'ROLE_View_Only','ROLE_Pay_Fees'] },
  },

  {
    path: "/feebalances",
    name: "Balances",
    component: () => import( "../components/finance/balances.vue"),
    meta: { requiresAuth: true ,requiresRoles: ['ROLE_All','ROLE_finance_admin', 
    'ROLE_View_Only','ROLE_Pay_Fees'] },
  },

  {
    path: "/pocketmoney",
    name: "pocketmoney",
    component: () => import("../components/finance/pocketmoney.vue"),
    meta: { requiresAuth: true ,requiresRoles: [
      'ROLE_All'
    , 'ROLE_finance_admin'
    , 'ROLE_Access_Pocket_Money'
    , 'ROLE_View_Only']},
  },







  {
    path: "/dashboard",
    name: "ExamDash",
    component: () => import(/* webpackChunkName: "ExamDash" */ "../components/exam/dashboard.vue"),
    meta: { requiresAuth: true ,requiresRoles: ['ROLE_All', 'ROLE_Exam_admin',
    'ROLE_Access_Exam_Analysis_Module'] },
  },
  {
    path: "/exams",
    name: "Exams",
    component: () => import(/* webpackChunkName: "Exams" */ "../components/exam/exams.vue"),
    meta: { requiresAuth: true ,requiresRoles: ['ROLE_All', 'ROLE_Exam_admin'] },
  },
  {
    path: "/mysubjects",
    name: "MySubjects",
    component: () => import( "../components/exam/mysubjects.vue"),
    meta: { requiresAuth: true ,requiresRoles: ['ROLE_All','ROLE_Exam_admin', 'ROLE_Access_Exam_Analysis_Module', 
    'ROLE_Exam_admin'] },
  },
  {
    path: "/myexams",
    name: "MyExams",
    component: () => import( "../components/exam/myexams.vue"),
    meta: { requiresAuth: true ,requiresRoles: ['ROLE_All','ROLE_Exam_admin', 'ROLE_Access_Exam_Analysis_Module'] },
  },
  {
    path: "/grades",
    name: "Grades",
    component: () => import( "../components/exam/grades.vue"),
    meta: { requiresAuth: true ,requiresRoles: ['ROLE_All', 'ROLE_Exam_admin'] },
  },
  {
    path: "/subjects",
    name: "Subjects",
    component: () => import( "../components/exam/subjects.vue"),
    meta: { requiresAuth: true ,requiresRoles: ['ROLE_All', 'ROLE_Exam_admin'] },
  },
  {
    path: "/subjectgroups",
    name: "SubjectGroups",
    component: () => import( "../components/exam/subjectgroups.vue"),
    meta: { requiresAuth: true ,requiresRoles: ['ROLE_All', 'ROLE_Exam_admin'] },
  },
  {
    path: "/examcategories",
    name: "ExamCategories",
    component: () => import( "../components/exam/examcategories.vue"),
    meta: { requiresAuth: true ,requiresRoles: ['ROLE_All', 'ROLE_Exam_admin'] },
  },
  {
    path: "/remarks",
    name: "Remarks",
    component: () => import( "../components/exam/remarks.vue"),
    meta: { requiresAuth: true ,requiresRoles: ['ROLE_All', 'ROLE_Exam_admin'] },
  },
  {
    path: "/gradingsystem",
    name: "GradingSystem",
    component: () => import( "../components/exam/gradingsystem.vue"),
    meta: { requiresAuth: true ,requiresRoles: ['ROLE_All', 'ROLE_Exam_admin'] },
  },
  {
    path: "/requiredsubs",
    name: "RequiredSubs",
    component: () => import( "../components/exam/requiredsubs.vue"),
    meta: { requiresAuth: true ,requiresRoles: ['ROLE_All', 'ROLE_Exam_admin'] },
  },
  {
    path: "/reportdates",
    name: "ReportDates",
    component: () => import( "../components/exam/reportdates.vue"),
    meta: { requiresAuth: true ,requiresRoles: ['ROLE_All', 'ROLE_Exam_admin'] },
  },
  {
    path: "/reportcolours",
    name: "ReportColours",
    component: () => import( "../components/exam/reportcolours.vue"),
    meta: { requiresAuth: true ,requiresRoles: ['ROLE_All', 'ROLE_Exam_admin'] },
  },




  {
    path: "/sms",
    name: "sms",
    component: () => import( "../components/bulksms/sms.vue"),
    meta: { requiresAuth: true ,requiresRoles: ['ROLE_All', 'ROLE_Send_Custom_sms'] },
  },

  {
    path: "/newsms",
    name: "newsms",
    component: () => import( "../components/bulksms/newmessage.vue"),
    meta: { requiresAuth: true ,requiresRoles: ['ROLE_All', 'ROLE_Send_Custom_sms'] },
  },

  {
    path: "/noticeboard",
    name: "noticeboard",
    component: () => import( "../components/bulksms/noticehistory.vue"),
    meta: { requiresAuth: true ,requiresRoles: ['ROLE_All', 'ROLE_Send_Custom_sms'] },
  },

  {
    path: "/newnotice",
    name: "newnotice",
    component: () => import( "../components/bulksms/newnotice.vue"),
    meta: { requiresAuth: true ,requiresRoles: ['ROLE_All', 'ROLE_Send_Custom_sms'] },
  },

  {
    path: "/newsletters",
    name: "newsletters",
    component: () => import( "../components/bulksms/newsletters.vue"),
    meta: { requiresAuth: true ,requiresRoles: ['ROLE_All', 'ROLE_Send_Custom_sms'] },
  },




// timetable
{
  path: "/timetable/dashboard",
  name: "Timetable",
  component: () => import(/* webpackChunkName: "Hr" */ "../components/timetable/Main.vue"),
  meta: {requiresAuth: true ,requiresRoles: ['ROLE_All', 'ROLE_timetable_admin'] },
},

{
  path: "/timetable/subjects",
  name: "SubjectsTT",
  component: () => import("../components/timetable/Subjects.vue"),
  meta: {requiresAuth: true ,requiresRoles: ['ROLE_All', 'ROLE_timetable_admin'] },
},

{
  path: "/timetable/classes",
  name: "ClassesTT",
  component: () => import(/* webpackChunkName: "Hr" */ "../components/timetable/Classes"),
  meta: {requiresAuth: true ,requiresRoles: ['ROLE_All', 'ROLE_timetable_admin'] },
},
{
  path: "/timetable/rooms",
  name: "Rooms",
  component: () => import("../components/timetable/Rooms.vue"),
  meta: {requiresAuth: true ,requiresRoles: ['ROLE_All', 'ROLE_timetable_admin'] },
},
{
  path: "/timetable/teachers",
  name: "Teachers",
  component: () => import("../components/timetable/Teachers.vue"),
  meta: {requiresAuth: true ,requiresRoles: ['ROLE_All', 'ROLE_timetable_admin'] },
},
{
  path: "/timetable/timetables",
  name: "Timetables",
  component: () => import("../components/timetable/Timetables.vue"),
  meta: {requiresAuth: true ,requiresRoles: ['ROLE_All', 'ROLE_timetable_admin'] },
},
{
  path: "/timetable/timeslots",
  name: "Timeslots",
  component: () => import("../components/timetable/Timeslots.vue"),
  meta: {requiresAuth: true ,requiresRoles: ['ROLE_All', 'ROLE_timetable_admin'] },
},
{
  path: "/timetable/days",
  name: "Days",
  component: () => import("../components/timetable/Days.vue"),
  meta: {requiresAuth: true ,requiresRoles: ['ROLE_All', 'ROLE_timetable_admin'] },
},
{
  path: "/timetable/tags",
  name: "Tags",
  component: () => import("../components/timetable/Tags.vue"),
  meta: {requiresAuth: true ,requiresRoles: ['ROLE_All', 'ROLE_timetable_admin'] },
},
{
  path: "/timetable/lessons",
  name: "Lessons",
  component: () => import("../components/timetable/Lessons.vue"),
  meta: {requiresAuth: true ,requiresRoles: ['ROLE_All', 'ROLE_timetable_admin'] },
},
{
  path: "/timetable/subs",
  name: "Subs",
  component: () => import("../components/timetable/Subs.vue"),
  meta: {requiresAuth: true ,requiresRoles: ['ROLE_All', 'ROLE_timetable_admin'] },
},
{
  path: "/timetable/teacherStatistics",
  name: "TeacherStatistics",
  component: () => import("../components/timetable/TeacherStatistics.vue"),
  meta: {requiresAuth: true ,requiresRoles: ['ROLE_All', 'ROLE_timetable_admin'] },
},
{
  path: "/timetable/classStatistics",
  name: "ClassStatistics",
  component: () => import("../components/timetable/ClassStatistics.vue"),
  meta: {requiresAuth: true ,requiresRoles: ['ROLE_All', 'ROLE_timetable_admin'] },
},
{
  path: "/timetable/roomStatistics",
  name: "RoomStatistics",
  component: () => import("../components/timetable/RoomStatistics.vue"),
  meta: {requiresAuth: true ,requiresRoles: ['ROLE_All', 'ROLE_timetable_admin'] },
},
{
  path: "/timetable/subjectStatistics",
  name: "SubjectStatistics",
  component: () => import("../components/timetable/SubjectStatistics.vue"),
  meta: {requiresAuth: true ,requiresRoles: ['ROLE_All', 'ROLE_timetable_admin'] },
},
{
  path: "/timetable/lessons/new",
  name: "AddLesson",
  component: () => import("../components/timetable/AddLesson.vue"),
  meta: {requiresAuth: true ,requiresRoles: ['ROLE_All', 'ROLE_timetable_admin'] },
},
{
  path: "/timetable/subjectLessons/:id",
  name: "SubjectLessons",
  component: () => import("../components/timetable/SubjectLessons.vue"),
  meta: {requiresAuth: true ,requiresRoles: ['ROLE_All', 'ROLE_timetable_admin'] },
},
{
  path: "/timetable/subjectTimeoffs/:id",
  name: "SubjectTimeoffs",
  component: () => import("../components/timetable/SubjectTimeoffs.vue"),
  meta: {requiresAuth: true ,requiresRoles: ['ROLE_All', 'ROLE_timetable_admin'] },
},
{
  path: "/timetable/roomLessons/:id",
  name: "RoomLessons",
  component: () => import("../components/timetable/RoomLessons.vue"),
  meta: {requiresAuth: true ,requiresRoles: ['ROLE_All', 'ROLE_timetable_admin'] },
},
{
  path: "/timetable/roomTimeoffs/:id",
  name: "RoomTimeoffs",
  component: () => import("../components/timetable/RoomTimeoffs.vue"),
  meta: {requiresAuth: true ,requiresRoles: ['ROLE_All', 'ROLE_timetable_admin'] },
},
{
  path: "/timetable/classLessons/:id",
  name: "ClassLessons",
  component: () => import("../components/timetable/ClassLessons.vue"),
  meta: {requiresAuth: true ,requiresRoles: ['ROLE_All', 'ROLE_timetable_admin'] },
},
{
  path: "/timetable/classTimeoffs/:id",
  name: "ClassTimeoffs",
  component: () => import("../components/timetable/ClassTimeoffs.vue"),
  meta: {requiresAuth: true ,requiresRoles: ['ROLE_All', 'ROLE_timetable_admin'] },
},

{
  path: "/timetable/teacherLessons/:id",
  name: "TeacherLessons",
  component: () => import("../components/timetable/TeacherLessons.vue"),
  meta: {requiresAuth: true ,requiresRoles: ['ROLE_All', 'ROLE_timetable_admin'] },
},
{
  path: "/timetable/teacherTimeoffs/:id",
  name: "TeacherTimeoffs",
  component: () => import("../components/timetable/TeacherTimeoffs.vue"),
  meta: {requiresAuth: true ,requiresRoles: ['ROLE_All', 'ROLE_timetable_admin'] },
},
{
  path: "/timetable/lessons/edit/:id",
  name: "Editlesson",
  component: () => import("../components/timetable/EditLesson.vue"),
  meta: {requiresAuth: true ,requiresRoles: ['ROLE_All', 'ROLE_timetable_admin'] },
},






    // INVENTORY
    {
      path: "/inventory/dashboard",
      name: "Inventory",
      component: () => import("../components/inventory/dashboard.vue"),
      meta: {requiresAuth: true},
  },
  {
      path: "/inventory/items",
      name: "Items",
      component: () => import("../components/inventory/items.vue"),
      meta: {requiresAuth: true},
  },
  {
      path: "/inventory/vendors",
      name: "Vendors",
      component: () => import("../components/inventory/vendors.vue"),
      meta: {requiresAuth: true},
  },
  {
      path: "/inventory/bills",
      name: "Bills",
      component: () => import("../components/inventory/bills.vue"),
      meta: {requiresAuth: true},
  },
  {
      path: "/inventory/adjustment",
      name: "Adjustment",
      component: () => import("../components/inventory/adjustment.vue"),
      meta: {requiresAuth: true},
  },
  {
      path: "/inventory/requisition",
      name: "Requisition",
      component: () => import("../components/inventory/requisition.vue"),
      meta: {requiresAuth: true},
  },
  {
      path: "/inventory/requisition/add",
      name: "AddRequisition",
      component: () => import("../components/inventory/newRequisition.vue"),
      meta: {requiresAuth: true},
  },
  {
      path: "/inventory/po",
      name: "PurchaseOrders",
      component: () => import("../components/inventory/purchase_orders.vue"),
      meta: {requiresAuth: true},
  },
  {
      path: "/inventory/newPO/:id",
      name: "NewPO",
      component: () => import("../components/inventory/newPurchaseOrder.vue"),
      meta: {requiresAuth: true},
  },
  {
      path: "/inventory/assets",
      name: "AssetManagement",
      component: () => import("../components/inventory/assetManagement.vue"),
      meta: {requiresAuth: true},
  },
  {
      path: "/inventory/receive/:id",
      name: "ReceiveItems",
      component: () => import("../components/inventory/receiveItems.vue"),
      meta: {requiresAuth: true},
  },
  {
      path: "/inventory/payment/request",
      name: "PaymentRequest",
      component: () => import("../components/inventory/requestPayment.vue"),
      meta: {requiresAuth: true},
  },
  {
      path: "/inventory/items/issue",
      name: "IssueItems",
      component: () => import("../components/inventory/issueItems.vue"),
      meta: {requiresAuth: true},
  },
  {
      path: "/inventory/items/return",
      name: "ReturnItems",
      component: () => import("../components/inventory/returnItems.vue"),
      meta: {requiresAuth: true},
  },

  // LIBRARY
  {
      path: "/library/dashboard",
      name: "Library",
      component: () => import("../components/library/dashboard.vue"),
      meta: {requiresAuth: true},
  },
  {
      path: "/library/books",
      name: "Books",
      component: () => import("../components/library/books.vue"),
      meta: {requiresAuth: true},
  },
  {
      path: "/library/book/view/:id",
      name: "ViewBook",
      component: () => import("../components/library/viewBook.vue"),
      meta: {requiresAuth: true},
  },
  {
      path: "/library/fines",
      name: "Fines",
      component: () => import("../components/library/fines.vue"),
      meta: {requiresAuth: true},
  },
  {
      path: "/library/books/issue",
      name: "IssueBooks",
      component: () => import("../components/library/issueBooks.vue"),
      meta: {requiresAuth: true},
  },
  {
      path: "/library/books/return",
      name: "ReturnBooks",
      component: () => import("../components/library/returnBooks.vue"),
      meta: {requiresAuth: true},
  },





  //Biometrics

  {
    path: "/biometrics/dashboard",
    name: "Biometrics",
    component: () => import(/* webpackChunkName: "Biometrics" */ "../components/biometrics/dashboard.vue"),
    meta: { requiresAuth: true ,requiresRoles: ['ROLE_All','ROLE_biometrics_admin'] },
  },
  {
    path: "/biometrics/students",
    name: "Biostudents",
    component: () => import(/* webpackChunkName: "Biostudents" */ "../components/biometrics/Biostudents.vue"),
    meta: { requiresAuth: true ,requiresRoles: ['ROLE_All','ROLE_biometrics_admin'] },
  },

  {
    path: "/biometrics/teachers",
    name: "BioTeachers",
    component: () => import(/* webpackChunkName: "BioTeachers" */ "../components/biometrics/Bioteachers.vue"),
    meta: { requiresAuth: true ,requiresRoles: ['ROLE_All','ROLE_biometrics_admin'] },
  },

  {
    path: "/biometrics/staff",
    name: "BioStaff",
    component: () => import(/* webpackChunkName: "BioStaff" */ "../components/biometrics/Biostaff.vue"),
    meta: { requiresAuth: true ,requiresRoles: ['ROLE_All' ,'ROLE_biometrics_admin'] },
  },

  {
    path: "/biometrics/sentsms",
    name: "SentSms",
    component: () => import(/* webpackChunkName: "SentSms" */ "../components/biometrics/sentsms.vue"),
    meta: { requiresAuth: true ,requiresRoles: ['ROLE_All','ROLE_biometrics_admin'] },
  },

  {
    path: "/biometrics/classes",
    name: "Classes",
    component: () => import(/* webpackChunkName: "Classes" */ "../components/biometrics/Bioclasses.vue"),
    meta: { requiresAuth: true ,requiresRoles: ['ROLE_All', 'ROLE_biometrics_admin'
    ,'ROLE_biometrics_admin'] },
  },


  {
    path: "/biometrics/classregister",
    name: "Register",
    component: () => import(/* webpackChunkName: "Classes" */ "../components/biometrics/Register.vue"),
    meta: { requiresAuth: true ,requiresRoles: ['ROLE_All','ROLE_Mark_attendance_register','ROLE_biometrics_admin'] },
  },


  {
    path: "/biometrics/reasons",
    name: "Reasons",
    component: () => import(/* webpackChunkName: "Reasons" */ "../components/biometrics/reasons.vue"),
    meta: { requiresAuth: true ,requiresRoles: ['ROLE_All' ,'ROLE_biometrics_admin'] },
  },
  {
    path: "/biometrics/gadgets",
    name: "Gadgets",
    component: () => import(/* webpackChunkName: "Gadgets" */ "../components/biometrics/gadgets.vue"),
    meta: { requiresAuth: true ,requiresRoles: ['ROLE_All','ROLE_biometrics_admin'] },
  },
  {
    path: "/biometrics/settings",
    name: "BioSettings",
    component: () => import(/* webpackChunkName: "BioSettings" */ "../components/biometrics/biometricssettings.vue"),
    meta: { requiresAuth: true ,requiresRoles: ['ROLE_All','ROLE_biometrics_admin'] },
  },

  


  // Hr
  {
    path: "/hr",
    name: "Hr",
    component: () => import(/* webpackChunkName: "Hr" */ "../components/payroll/dashboard.vue"),
    meta: { requiresAuth: true ,requiresRoles: ['ROLE_All', 'ROLR_HR_ADMIN','ROLE_Add_or_Edit_Employee'] },
  },
  {
    path: "/employees",
    name: "Employees",
    component: () => import(/* webpackChunkName: "Employees" */ "../components/payroll/Employees.vue"),
    meta: { requiresAuth: true ,requiresRoles: ['ROLE_All', 'ROLR_HR_ADMIN','ROLE_Add_or_Edit_Employee'] },
  },
  {
    path: "/attendance",
    name: "Attendance",
    component: () => import(/* webpackChunkName: "Attendance" */ "../components/payroll/attendance.vue"),
    meta: { requiresAuth: true ,requiresRoles: ['ROLE_All', 'ROLR_HR_ADMIN','ROLE_Add_or_Edit_Employee'] },
  },
  {
    path: "/studentattendance",
    name: "StudentAttendance",
    component: () => import(/* webpackChunkName: "StudentAttendance" */ "../components/biometrics/attendance.vue"),
    meta: { requiresAuth: true ,requiresRoles: ['ROLE_All', 'ROLR_HR_ADMIN','ROLE_Add_or_Edit_Employee'] },
  },
  {
    path: "/hrsettings",
    name: "Hrsettings",
    component: () => import(/* webpackChunkName: "Hrsettings" */ "../components/payroll/hrsettings.vue"),
    meta: { requiresAuth: true ,requiresRoles: ['ROLE_All', 'ROLR_HR_ADMIN','ROLE_Add_or_Edit_Employee'] },
  },
  {
    path: "/payrolls",
    name: "Payrolls",
    component: () => import(/* webpackChunkName: "Payrolls" */ "../components/payroll/Payrolls.vue"),
    meta: { requiresAuth: true ,requiresRoles: ['ROLE_All', 'ROLR_HR_ADMIN','ROLE_Add_or_Edit_Employee'] },
  },
  {
    path: "/allowances",
    name: "Earnings",
    component: () => import(/* webpackChunkName: "Earnings" */ "../components/payroll/Earnings.vue"),
    meta: { requiresAuth: true ,requiresRoles: ['ROLE_All', 'ROLR_HR_ADMIN','ROLE_Add_or_Edit_Employee'] },
  },
  {
    path: "/taxbands",
    name: "Kra",
    component: () => import(/* webpackChunkName: "Kra" */ "../components/payroll/kra.vue"),
    meta: { requiresAuth: true ,requiresRoles: ['ROLE_All', 'ROLR_HR_ADMIN','ROLE_Add_or_Edit_Employee'] },
  },
  {
    path: "/nhifbands",
    name: "Nhif",
    component: () => import(/* webpackChunkName: "Nhif" */ "../components/payroll/nhif.vue"),
    meta: { requiresAuth: true ,requiresRoles: ['ROLE_All', 'ROLR_HR_ADMIN','ROLE_Add_or_Edit_Employee'] },
  },
  {
    path: "/nssfbands",
    name: "Nssf",
    component: () => import(/* webpackChunkName: "Nssf" */ "../components/payroll/nssf.vue"),
    meta: { requiresAuth: true ,requiresRoles: ['ROLE_All', 'ROLR_HR_ADMIN','ROLE_Add_or_Edit_Employee'] },
  },
  {
    path: "/housebands",
    name: "Housing",
    component: () => import(/* webpackChunkName: "Housing" */ "../components/payroll/housing.vue"),
    meta: { requiresAuth: true ,requiresRoles: ['ROLE_All', 'ROLR_HR_ADMIN','ROLE_Add_or_Edit_Employee'] },
  },
  {
    path: "/leave",
    name: "Leave",
    component: () => import(/* webpackChunkName: "Leave" */ "../components/payroll/leave.vue"),
    meta: { requiresAuth: true ,requiresRoles: ['ROLE_All', 'ROLR_HR_ADMIN','ROLE_Add_or_Edit_Employee'] },
  },
  {
    path: "/deductions",
    name: "Deductions",
    component: () => import(/* webpackChunkName: "Deductions" */ "../components/payroll/Deductions.vue"),
    meta: { requiresAuth: true ,requiresRoles: ['ROLE_All', 'ROLR_HR_ADMIN','ROLE_Add_or_Edit_Employee'] },
  },


  


  // Settings
  
  {
    path: "/settings",
    name: "Settings",
    component: () => import(/* webpackChunkName: "Settings" */ "../components/settings/settings.vue"),
    meta: { requiresAuth: true ,requiresRoles: ['ROLE_All'] },
  },

  {
    path: "/users",
    name: "Users",
    component: () => import(/* webpackChunkName: "Settings" */ "../components/settings/users.vue"),
    meta: { requiresAuth: true ,requiresRoles: ['ROLE_All'] },
  },

  {
    path: "/locations",
    name: "Locations",
    component: () => import(/* webpackChunkName: "Settings" */ "../components/settings/locations.vue"),
    meta: { requiresAuth: true ,requiresRoles: ['ROLE_All'] },
  },

  {
    path: "/routes",
    name: "Routes",
    component: () => import(/* webpackChunkName: "Settings" */ "../components/settings/routes.vue"),
    meta: { requiresAuth: true ,requiresRoles: ['ROLE_All'] },
  },

  {
    path: "/settingsclasses",
    name: "settingClasses",
    component: () => import(/* webpackChunkName: "Settings" */ "../components/settings/classes.vue"),
    meta: { requiresAuth: true ,requiresRoles: ['ROLE_All'] },
  },

  {
    path: "/courses",
    name: "Courses",
    component: () => import(/* webpackChunkName: "Settings" */ "../components/settings/courses.vue"),
    meta: { requiresAuth: true ,requiresRoles: ['ROLE_All'] },
  },

  {
    path: "/departments",
    name: "Departments",
    component: () => import(/* webpackChunkName: "Settings" */ "../components/settings/departments.vue"),
    meta: { requiresAuth: true ,requiresRoles: ['ROLE_All'] },
  },

  {
    path: "/terms",
    name: "Terms",
    component: () => import(/* webpackChunkName: "Settings" */ "../components/settings/terms.vue"),
    meta: { requiresAuth: true ,requiresRoles: ['ROLE_All'] },
  },

  {
    path: "/hostels",
    name: "Hostels",
    component: () => import(/* webpackChunkName: "Settings" */ "../components/settings/hostels.vue"),
    meta: { requiresAuth: true ,requiresRoles: ['ROLE_All'] },
  },


  





  // E-learning 
  {
    path: "/E-learning",
    name: "E-learning",
    component: () => import(/* webpackChunkName: "Settings" */ "../components/Elearning/dashboard.vue"),
    meta: { requiresAuth: true ,requiresRoles: ['ROLE_All','ROLE_post_content'] },
  },

  {
    path: "/dashboard",
    name: "Dashboard",
    component: () => import(/* webpackChunkName: "Hr" */ "../components/Elearning/dashboard.vue"),
    meta: { requiresAuth: true ,requiresRoles: ['ROLE_All','ROLE_post_content']  },
  },

  {
    path: "/assignments",
    name: "Assignments",
    component: () => import(/* webpackChunkName: "Hr" */ "../components/Elearning/assignments.vue"),
    meta: { requiresAuth: true ,requiresRoles: ['ROLE_All','ROLE_post_content']  },
  },

  {
    path: "/selftests",
    name: "Selftests",
    component: () => import(/* webpackChunkName: "Hr" */ "../components/Elearning/assignments.vue"),
    meta: { requiresAuth: true ,requiresRoles: ['ROLE_All','ROLE_post_content']  },
  },

  {
    path: "/thinktank",
    name: "thinktank",
    component: () => import(/* webpackChunkName: "Hr" */ "../components/Elearning/revision.vue"),
    meta: { requiresAuth: true ,requiresRoles: ['ROLE_All','ROLE_post_content']   },
  },

  {
    path: "/ai",
    name: "Ai",
    component: () => import(/* webpackChunkName: "Hr" */ "../components/Elearning/ai.vue"),
    meta: { requiresAuth: true ,requiresRoles: ['ROLE_All','ROLE_post_content']   },
  },

  {
    path: "/videoclasses",
    name: "vodeoclasses",
    component: () => import(/* webpackChunkName: "Hr" */ "../components/Elearning/vodeoclasses.vue"),
    meta: { requiresAuth: true ,requiresRoles: ['ROLE_All','ROLE_post_content']   },
  },

  
  {
    path: "/liveclasses",
    name: "liveclasses",
    component: () => import(/* webpackChunkName: "Hr" */ "../components/Elearning/liveclasses.vue"),
    meta: { requiresAuth: true  ,requiresRoles: ['ROLE_All','ROLE_post_content']  },
  },

  {
    path: "/pastpapers",
    name: "pastpapers",
    component: () => import(/* webpackChunkName: "Hr" */ "../components/Elearning/pastpapers.vue"),
    meta: { requiresAuth: true  ,requiresRoles: ['ROLE_All','ROLE_post_content']  },
  },

  {
    path: "/notes",
    name: "notes",
    component: () => import(/* webpackChunkName: "Hr" */ "../components/Elearning/notes.vue"),
    meta: { requiresAuth: true  ,requiresRoles: ['ROLE_All','ROLE_post_content']  },
  },

  {
    path: "/interactive",
    name: "interactive",
    component: () => import(/* webpackChunkName: "Hr" */ "../components/Elearning/quizes.vue"),
    meta: { requiresAuth: true  ,requiresRoles: ['ROLE_All','ROLE_post_content']  },
  },
  

];


const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  
  scrollBehavior(to, from, SavedPosition) {
    if (to.hash) {
      const el = window.location.href.split("#")[1];
      if (el.length) {
        document.getElementById(el).scrollIntoView({ behavior: "smooth" });
      }
    } else if (SavedPosition) {
      return SavedPosition;
    } else {
      document.getElementById("sidenavmain").scrollIntoView({ behavior: "smooth" });
    }
  },
  
});



router.beforeEach((to, from, next) => {
  const publicPages = ['/'];
  const authRequired = !publicPages.includes(to.path);
  const isAuthenticated = store.getters.isAuthenticated || localStorage.getItem("authToken");

  let modalBackground = document.querySelector('.modal-backdrop')
  if (modalBackground) {
    modalBackground.remove()
  }


  if ((authRequired && !isAuthenticated)) {
    next('/');
  } else {

    const requiredRoles = to.meta.requiresRoles;

    if((requiredRoles) && to.path!='/home'){
      
      const userRoles  = Cookies.get('userRoles');
      const hasRequiredRole = userRoles.some(userRole => requiredRoles.includes(userRole));
      if (hasRequiredRole) {
        next();
      } else {
        window.$('#myModalaccessdenied').modal('show');
      }
      
    }else{
      next();
    }
    
  }

});





export default router;
