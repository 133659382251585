import { createApp } from 'vue'
import store from "./store/index.js";
import router from "./router";
import App from './App.vue'
import "@/assets/css/nucleo-icons.css";
import "@/assets/css/nucleo-svg.css";
import "@/assets/css/modlacss.css";
import 'perfect-scrollbar/css/perfect-scrollbar.css';
import "@/assets/css/material-dashboard.min.css";
import "@/assets/css/dataTables.responsive.css";
import "@/assets/jsmuoltistepcss/MultiStepTeachers.css"; 
import "@/assets/css/mainimagecapture.css"; 
import "@/assets/css/jquery.treetable.theme.default.css"; 
import "@/assetsbio/css/stylelistview.css"; 
import "@/assets/source/css/videoplayer.min.css"; 
import "@/assets/source/css/prism.css"; 



import "@/assets/jsmuoltistepjs/MultiStep.js";
import "@/assetsbio/js/plugins/dropzone.min.js"; 
import "@/assetsbio/js/plugins/quill.min.js"; 
import "@/assets/js/plugins/smooth-scrollbar.min.js"; 
import "@/assets/js/plugins/perfect-scrollbar.min.js"; 
import "@/assets/js/plugins/round-slider.min.js";

import "@/assets/source/js/prism.js"; 


import { activateDarkMode } from "@/assets/js/dark-mode";


const appInstance = createApp(App);
appInstance.use(store);
appInstance.use(router);


if (localStorage.getItem("theme") === "1") {
  activateDarkMode();
  store.state.isDarkMode = true;
}else{
  store.state.isDarkMode = false;  
}

appInstance.mount("#app");

