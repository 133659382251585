import { createStore } from "vuex";

import apiService from '@/apis/apiService';

export default createStore({
  state: {
    hideConfigButton: false,
    isPinned: true,
    showConfig: false,
    sidebarType: "bg-gradient-dark",
    isRTL: false,
    color: "success",
    isNavFixed: false,
    isAbsolute: false,
    showNavs: true,
    showSidenav: true,
    showNavbar: true,
    showFooter: true,
    showMain: true,
    isDarkMode: false,
    navbarFixed:
      "position-sticky blur shadow-blur left-auto top-1 z-index-sticky px-0 mx-4",
    absolute: "position-absolute px-4 mx-0 w-100 z-index-2",
    authToken: localStorage.getItem("authToken") || null,
    user: null,
    isAuthenticated: false,
    showSidebar: true,
    isMobileScreen: false,
    tableData: [],
    presentperclass:[],
    fileUrlPdf: null

  },

  mutations: {

    setPdfFileValue(state, value) {
      state.fileUrlPdf = value;
    },

    setRegStudentsPopulationTable(state, value) {
      state.tableData = value;
    },

    setBioPopulationInTable(state, value) {
      state.presentperclass = value;
    },


    setDarkMode(state, value) {
      state.isDarkMode = value;
    },
    toggleConfigurator(state) {
      state.showConfig = !state.showConfig;
    },
    navbarMinimize(state) {
      const sidenav_show = document.querySelector(".g-sidenav-show");
      const iconSidenav = document.getElementById('iconSidenav');


      if (sidenav_show.classList.contains("g-sidenav-pinned")) {
        sidenav_show.classList.remove("g-sidenav-pinned");
        iconSidenav.classList.add('d-none');
        state.isPinned = true;
      } else {
        sidenav_show.classList.add("g-sidenav-pinned");
        iconSidenav.classList.remove('d-none');
        state.isPinned = false;
      }
    },
    navbarFixed(state) {
      if (state.isNavFixed === false) {
        state.isNavFixed = true;
      } else {
        state.isNavFixed = false;
      }
    },
    toggleEveryDisplay(state) {
      state.showNavbar = !state.showNavbar;
      state.showSidenav = !state.showSidenav;
      state.showFooter = !state.showFooter;
    },
    toggleHideConfig(state) {
      state.hideConfigButton = !state.hideConfigButton;
    },
    color(state, payload) {
      state.color = payload;
    },

    setToken(state, token) {
      state.authToken = token;
    },

    setUser(state, user) {
      state.user = user;
      state.isAuthenticated = true;
    },

    clearAuthData(state) {
      state.authToken = null;
      state.user = null;
      state.isAuthenticated = false;
    },

    setIsMobileScreen(state, isMobile) {
      state.isMobileScreen = isMobile;
    },

  },

  actions: {

    setColor({ commit }, payload) {
      commit("color", payload);
    },

    async login({ commit }, { token, user }) { // Declare as async
      commit('setToken', token);
      commit('setUser', user);
    },
    
    logout({commit}) {
      commit('clearAuthData');
    },

    updatePdfFileValue({ commit }, pdfUrl) {
      commit('setPdfFileValue', pdfUrl);
    },


    async fetchTableData({ commit }) {
      try {
        const response = await apiService.getpopulationperclass(); 
        commit('setRegStudentsPopulationTable', response); 
      } catch (error) {
        console.error('Error fetching table data:', error);
      }    
    },


    async fetchBioTableData({ commit }) {
      try {
        const response = await apiService.apiClient.get("/api/bio/getBioAttendancetable");; 
        commit('setBioPopulationInTable', response.data); 
      } catch (error) {
        console.error('Error fetching table data:', error);
      }    
    },


  },

  getters: {
    isAuthenticated: (state) => state.isAuthenticated,
    currentUser: (state) => state.user,
  },
});

